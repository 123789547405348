import React from "react";

const NotFoundPage = () => (
  <div style={{
    position: 'absolute',
    width: '100%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  }}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </div>
  </div>
);

export default NotFoundPage;
